<template>
	<div class="bigBox">
		<el-page-header @back="goBack" content="客户详情" class="heardGoBack">
		</el-page-header>
		<el-divider></el-divider>
		<div class="">
			<el-form :model="form" label-width="120px" label-position="right">
				<el-form-item label="公司名称">
					<span>{{detalisArr.company_name}}</span>
				</el-form-item>
				<el-form-item label="行业名称">
					<span>{{detalisArr.trade_name}}</span>
				</el-form-item>
				<el-form-item label="联系人">
					<span>{{detalisArr.contacts}}</span>
				</el-form-item>
				<el-form-item label="电话">
					<span>{{detalisArr.mobile}}</span>
				</el-form-item>
				<el-form-item label="邮箱">
					<span>{{detalisArr.email}}</span>
				</el-form-item>
				<el-form-item label="地址">
					<span>{{detalisArr.address}}</span>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {
		userInfoDetails
	} from "@/api/customerList/customerList.js"
	export default {
		data() {
			return {
				detalisArr: [],
			}
		},
		mounted() {
			this.getDetalisArr()
		},
		methods: {
			goBack() {
				this.$router.go(-1);

			},
			async getDetalisArr() {
				let id = this.$route.params.id
				const res = await userInfoDetails({
					id: id,
				})
				// 详情数据
				this.detalisArr = res.data
			}
		}

	}
</script>

<style lang="scss" scoped>
	.bigBox {
		background: #FFFFFF;
		margin: 20px;
		padding: 30px;
	}

	::v-deep .el-button--primary {
		width: 100px;
		height: 40px;
	}
</style>